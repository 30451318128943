import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './header.scss';


import Lottie from 'lottie-react'
import animationData from '../../assets/data-animation2.json'


const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }

  }
}


const Header = () => {

  return (
    <div className='app__header app__flex'>
      <motion.div whileInView={{ x: [-100, 0 ], opacity: [0, 1]}} transition={{ duration: 2 }} className='app__header-info'>
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <span>👋</span>
            <div style={{marginLeft: 20}}>
              <p className='p-text'>Hello, I am</p>
              <h1 className='head-text'>Jaime</h1>
            </div>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Data Scientist</p>
            <p className='p-text'>Web Developer</p>
            <p className='p-text'>Data Analyst</p>
            <p className='p-text'>Musician</p>
          </div>
        </div>
      </motion.div>

      <motion.div whileInView={{ opacity: [0, 1]}} transition={{ duration: 1, delayChildren: 1 }} className='app__header-img'>
        {/*<img src={images.profile} alt='profile pic'/>*/}
        <Lottie animationData={animationData}/>
         
      </motion.div>

      <motion.div variant={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className='app__header-circles'>
        {[ images.cpp, images.python, images.java, images.javascript ].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='circle'/>
          </div>
        ))}
      </motion.div>




    </div>
  )
}

export default AppWrap(Header, 'home');
/*export default Header*/

/*<motion.img whileInView={{ scale: [0, 1]}} 
        transition={{ duration: 1, ease: 'easeInOut' }} 
        className='overlay_circle'
        src={images.circle}
        alt='profile-circle'/>*/