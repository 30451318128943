import React from "react";
import './app.scss'
import 'react-tooltip/dist/react-tooltip.css'

import {About, Footer, Header, Skills, Testimonial, Work} from './container'
import { NavBar } from "./components";

const App = () => {
    return (
        <div className="app">
            <NavBar />
            <Header />
            <About />
            <Work />
            <Skills />
            <Testimonial />
            <Footer />
        </div>
    )
};

export default App;