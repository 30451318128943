import React, { useState, useEffect } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import './navbar.scss';

import { images } from '../../constants';

const NavBar = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbarMenu = document.getElementById("navbarMenu");
      if (!navbarMenu.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <img src={images.logo} alt='logo'/> 
      </div>
      <ul className='app__navbar-links'>
        {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
          <li className='app__flex p-text' key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-menu" id="navbarMenu" style={{ display: menuOpen ? 'block' : 'none' }}>
        <div>
          <HiX className="app__navbar-menu__close" id="closeMenuButton" onClick={() => setMenuOpen(false)} />
          <ul>
            {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
              <li key={item}>
                <div />
                <a href={`#${item}`} onClick={() => setMenuOpen(false)}>{item}</a>
              </li>
            ))}
          </ul>
        </div>  
      </div>
      {/* Conditionally render burger menu icon or close icon based on menuOpen state */}
      {!menuOpen ? (
        <div className='app__navbar-burger' onClick={() => setMenuOpen(true)}>
          <HiMenuAlt4 />
        </div>
      ) : null}
      
    </nav>
  )
}

export default NavBar

{/*
      <div className='app__navbar-menu'>
          <HiMenuAlt4 onClick={() => setToggle(true)}/>

          {toggle && (
            <motion.div whileInView={{ x: [300, 0] }} transition={{ duration: 0.85, ease: 'easeInOut'}}>
              <HiX className='app__navbar-menu__close' onClick={() => setToggle(false)} />
              <ul>
                {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                  <li key={item}>
                    <div />
                    <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
      </div>
      */}